<template>
  <body style="position: relative;">
    <div id="wrap">
      <section>
        <headerLayout ref="headerComponent"></headerLayout>
      </section>
      <main>
        <section class="sub-visual">
          <div class="innWrap">
            <div class="txt">
              <h2>가입정보</h2>
              <!-- <p>국내 최대 용량의 기업전용망을 통한 고품질 인터넷 전용회선 서비스</p> -->
            </div>
            <div class="location">
              <ul>
                <li><a href="#">MY</a></li>
                <li><a href="#">가입상품관리</a></li>
                <li><span>가입정보</span></li>
              </ul>
            </div>
          </div>
        </section>

        <section class="sub-section ctInfo">
          <div class="innWrap subTab01 flex">
            <div class="nav">
              <ul>
                <li>
                  <a v-bind:class="{ active: active }" @click="active = true">고객정보</a>
                </li>
                <li>
                  <a v-bind:class="{ active: !active }" @click="active = false">서비스정보</a>
                </li>
              </ul>
            </div>
            <div class="con">
              <transition name="fade">
                <div id="tab01" v-show="this.active">
                  <div class="searchBox">
                    <div class="select">
                      <span class="placeholder">
												<span class="fcGy">고객ID를 선택하세요.</span>
											</span>
                      <div class="list">
                        <ul>
                          <li v-for="item in custIdList" :key="item.custId" :value="item.custId" @click="selectValue('custId', item)">{{item.custNm}} / {{item.custId}}</li>
                        </ul>
                      </div>
                    </div>
                    <a @click="searchBizCustInfo(custId)" class="btn-md02 btn-primary">조회</a>
                  </div>

                  <table class="tbl_type01">
                    <tbody>
                      <colgroup>
                        <col width="20%" />
                        <col width="*" />
                      </colgroup>
                      <tr>
                        <th>고객명</th>
                        <td>{{bizCustInfo.custNm}}</td>
                      </tr>
                      <tr>
                        <th>대표자명</th>
												<td>{{bizCustInfo.reprNm}}</td>
                      </tr>
                      <tr>
                        <th>사업자등록번호</th>
												<td>{{partyIdNumber2 | biznum}}</td>
                      </tr>
                      <tr>
                        <th>법인명</th>
												<td>{{bizCustInfo.corpNm}}</td>
                      </tr>
                      <tr>
                        <th>법인번호</th>
												<td>{{bizCustInfo.corpNo | corpnonum}}</td>
                      </tr>
                      <tr>
                        <th>연락처</th>
												<td>{{bizCustInfo.homeTelNo != null ? bizCustInfo.homeTelNo : bizCustInfo.mphonNo | phone}}</td>
                      </tr>
                      <tr>
                        <th>주소</th>
												<td>{{bizCustInfo.arnoAdrBasSbst}} {{bizCustInfo.arnoAdrDtlSbst}}</td>
                      </tr>
                      <tr>
                        <th>설치장소</th>
												<td>{{bizCustInfo.eqpArnoAdrBasSbst}} {{bizCustInfo.eqpArnoAdrDtlSbst}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </transition>
              <transition name="fade">
                <div id="tab02" v-show="!this.active">
                  <div class="searchBox">
                    <div class="select">
                      <span class="placeholder">
                        <span class="fcGy">상품명 / 전용회선번호 / 설치주소</span>
                        </span>
                      <div class="list">
                        <ul>
                          <li v-for="item in userSvcInfo" :key="item.scn" :value="item.scn" @click="selectValue('scn', item)">{{item.svctypename + (item.llnum == '' ? ' / - / ' : ' / '+ item.llnum + ' / ') + item.addr + item.addrnum + item.addrsubnum + item.detailaddr}}</li>
                        </ul>
                      </div>
                    </div>
                    <a @click="search(selectedScn)" class="btn-md02 btn-primary">조회</a>
                  </div>

                  <h4 style="margin-bottom:50px; !important">서비스 계약정보</h4>
                  <table class="tbl_type01" style="margin-top:50px; !important">
                    <tbody>
                      <tr>
                        <th>서비스계약번호</th>
                        <td>{{svcMasterInfo.scn}}</td>
                        <th class="bdl1px">가입상태</th>
                        <td>{{svcMasterInfo.svcstatecodedesc}}</td>
                        <th class="bdl1px">계약기간</th>
                        <td>{{svcMasterInfo.contractperiodtypecodedesc == '기간없음' ? '무약정' : svcMasterInfo.contractperiodtypecodedesc}}</td>
                      </tr>
                      <tr>
                        <th class="">고객ID</th>
                        <td>{{svcMasterInfo.custid}}</td>
                        <th class="bdl1px">서비스 변경일</th>
                        <td>{{svcMasterInfo.lstorderdt | dateFormat1}}</td>
                        <th class="bdl1px">서비스 시작일</th>
                        <td>{{svcMasterInfo.svcstartdt | dateFormat1}}</td>
                        <!-- <th class="bdl1px">장단기구분</th>
                        <td>{{svcMasterInfo.longcontractyn == null || svcMasterInfo.longcontractyn == '' ? '' : svcMasterInfo.longcontractyn == 'Y' ? '장기' : '단기'}}</td> -->
                      </tr>
                      <tr>
                        <th>서비스 번호</th>
                        <td>{{svcMasterInfo.svcnum}}</td>
                        <th class="bdl1px"></th>
                        <td></td>
                        <th class="bdl1px"></th>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>

                  <h4 style="margin-top:50px; !important">회선구성정보</h4>
                  <table class="tbl_type01" style="margin-top:50px; !important">
                    <tbody>
                      <tr>
                        <th>회선번호<br>(전용or인터넷)</th>
                        <td>{{svcMasterInfo.llnum}}</td>
                        <th class="bdl1px">상품명</th>
                        <td>{{svcMasterInfo.svctypecodedesc}}</td>
                        <th class="bdl1px">속도</th>
                        <td>{{svcMasterInfo.speedcodedesc}}</td>
                      </tr>
                      <tr>
                        <th>설치장소(지번)</th>
                        <td colspan="5">
                          {{svcMasterInfo.address}}
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <h4 style="margin-top:50px; margin-bottom:50px; !important" v-show="svcMasterInfo.addservices != ''">부가 서비스정보</h4>
                  <table class="tbl_type02" v-show="svcMasterInfo.addservices != ''">
                    <thead>
                      <tr>
                        <th>부가서비스 ID</th>
                        <th>부가서비스명</th>
                        <th>수량</th>
                        <th>비고</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="arr in addservices" :key="arr.addsvcscn" v-show="addservices">
													<td>{{arr.addsvcscn}}</td>
													<td>{{arr.addsvctypedesc}}</td>
													<td>{{arr.svcno}}</td>
													<td>{{arr.fixedipcnt}}</td>
                      </tr>
                      <tr v-show="!addservices">
                        <td colspan="4"> 조회된 데이터가 없습니다. </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </transition>
            </div>
          </div>
        </section>
      </main>
      <section>
        <footerLayout></footerLayout>
      </section>
    </div>
  </body>
</template>
<script>
import HeaderLayout from "./common/Header.vue";
import FooterLayout from "./common/Footer.vue";
import { gigaofficeService } from "Api";

export default {
  components: {
    HeaderLayout,
    FooterLayout
  },
  data() {
    return {
      active: true,
			custIdList: '',
      custId: '',
      selectedCustId: '',
      selectedScn: '',
      selectedSvcType: '',
			userSvcInfo: '',
      bizCustInfo: '',
      svcMasterInfo: '',
      addservices: '',
      partyIdNumber: '',
      partyIdNumber2: '',
      encPartyIdNumber1: '',
      encPartyIdNumber2: ''
    };
  },
  created: function() {},
  mounted: async function() {
    await this.getOwnerContactInfoByTokenId();
  },
  methods: {
    selectValue(type, obj) {
      this.custId = obj.custId
      this.selectedCustId = obj.encCustId
      if(type !== 'custId') {
        this.selectedScn = obj.encScn
        this.selectedSvcType = obj.svctypename
      }
    },
    search(scn) {
      if(this.selectedSvcType.indexOf('Basic') == '-1') {
        this.getSvcMasterInfo(scn)
      } else {
        this.getSvcMasterInfo2(scn)
      }
    },
    //1-1. 토큰으로 사업자 번호 조회
    async getOwnerContactInfoByTokenId() {
      let token_id = sessionStorage.getItem("accessToken");
      let param = { token_id: token_id };
      this.$store
        .dispatch("auth/getOwnerContactInfoByTokenId", param)
        .then(res => {
          // console.log(res.partyIdNumber1)
          this.ownerContactInfo = res;
          //개인
          if (sessionStorage.getItem("partyTypeId") === "01") {
            this.partyIdNumber = this.ownerContactInfo.partyIdNumber1;
          } else {
            this.partyIdNumber = this.ownerContactInfo.partyIdNumber2;
          }

          this.getUserInfo();
          setTimeout(() => {
            this.getUserSvcInfo(this.ownerContactInfo.partyIdNumber1, this.ownerContactInfo.partyIdNumber2, this.ownerContactInfo.encPartyIdNumber1 ,this.ownerContactInfo.encPartyIdNumber2);
          }, 100);
        })
        .catch(error => {
          console.error(error);
        });
    },
    //1-2. 사업자번호로 고객정보 조회 (콤보생성 bizcust)
    async getUserInfo() {
      // TODO TEST
      let param = { };
      await this.$store
        .dispatch("gigaoffice/getUserInfo", param)
        .then(res => {
          //고객정보 콤보(고객ID)
          this.custIdList = res.data.result;
          //서비스정보 콤보(상품명/전용회선번호/설치주소)
          // this.serviceInfoList = res.data
        })
        .catch(error => {
          console.log(error);
        });
    },

    //bizcust 고객정보 조회
    searchBizCustInfo(custId) {
      if (custId != "" && custId != null) {
        for (var i = 0; i < this.custIdList.length; i++) {
          if (custId == this.custIdList[i].custId) {
            this.bizCustInfo = this.custIdList[i];
            this.partyIdNumber2 = this.ownerContactInfo.partyIdNumber1;
          }
        }
      } else {
        alert("고객ID를 선택하세요.");
        return false
      }
    },
    async getUserSvcInfo(custNum1, custNum2, encCustNum1, encCustNum2) {
      try {
        let custType = "" ,temp = [] ,key = 'scn';
        custNum1.length === 13 ? (custType = 3) : (custType = 8);
        // TODO TEST
        let param = {
          custType: custType,
          custNum: encCustNum1
        };
        const res1 = await this.$store.dispatch(
          "gigaoffice/getUserSvcInfo",
          param
        );
        custNum2.length === 13 ? (custType = 3) : (custType = 8);
        // TODO TEST
        param = {
          custType: custType,
          custNum: encCustNum2
        };
        const res2 = await this.$store.dispatch(
          "gigaoffice/getUserSvcInfo",
          param
        );
        
        if(res1 !== undefined && res1.length > 0){
          res1.forEach(function(value ,index){
            temp.push(value)
          })
        }
        if(res2 !== undefined && res2.length > 0){
          res2.forEach(function(value ,index){
            temp.push(value)
          })
        }
        temp = temp.reduce((i ,item) => {
          const removed = i.filter(j => j[key] !== item[key])
          return [...removed ,item]
        }, [])
        this.userSvcInfo = temp
        // console.log(temp)

      } catch (err) {
        console.log(err);
      }
    },
    async getSvcMasterInfo(scn) {
      // alert(this.$refs.footer.confirmAlert('상품명/전용회선번호/설치주소를 선택하세요.'))
      if(scn == null | scn == '') {
        alert('상품명/전용회선번호/설치주소를 선택하세요.')
        return false
      }
      // this.init()
      let param = {
        scn: scn,
        custId: this.selectedCustId
      }
      //api호출
      let res = await gigaofficeService.getSvcMasterInfo(param)
      if(res.data.code == '100') {
        this.svcMasterInfo = res.data.result
        this.addservices = res.data.result.addservices
      } else if(res.data.code == '9250') {
        alert('조회된 데이터가 없습니다.')
      } else{
        alert(res.data.message)
      }
    },
    async getSvcMasterInfo2(scn) {
      let param = {
        scn: scn,
        custId: this.selectedCustId
      }
      //api호출
      let res = await gigaofficeService.getSvcFlexlineMasterInfo(param);
      if (res.data.code == "100") {
        this.svcMasterInfo = res.data.result
        this.addservices = res.data.result.addservices
        // console.log(res.data.result.addservices)
        // this.addservices = res.data.result.addservices[0];
      } else if (res.data.code == "9250") {
        alert("조회된 데이터가 없습니다.");
      } else {
        alert(res.data.message);
      }
    },
  }
};
</script>
<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
